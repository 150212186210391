import React, { useState, useEffect } from "react"
import Main from "../Main"
import './Tratamentos.css'

import ModalGrupo from '../user/modal/ModalGrupo'
import { useNavigate } from "react-router-dom"
import { put,get,post } from '../../main/register'

const headerProps = {
     icon: 'plus-square',
     title: 'Cadastro das Fórmulas',
     subtitle: 'Cadastro da Tratamentos'
}

let nomeFormula = ''

export default function Tratamentos() {
     const [grupos, setGrupos] = useState([])
     const [showPostModal, setShowPostModal] = useState(false)
     const [tratamentos, setTratamentos] = useState([])

     const [subGrupos, setSubGrupos] = useState([]) 
     const [grupoPrincipal, setGrupoPrincipal] = useState(0)
     const [subGrupoPrincipal, setSubGrupoPrincipal] = useState(0)
     const [tratamentoPrincipal, setTratamentoPrincipal] = useState(0)

     const [componente,setComponente]=useState('')
     const [origemSave, setOrigemSave] = useState ({})
     const navigate = useNavigate();

     useEffect(() => {     /* busca Grupos na API quando é renderizado */
          carregaGrupos()
     }, [])

     useEffect(()=>{     /* Chama a função CarregaSubGrupos toda vez que o GrupoPrincipal sofrer alteração */
          carregaSubGrupos()
     },[grupoPrincipal])

     useEffect(() =>{    /* Chama a função CarregaTratamentos toda vez que o subGrupoPrincipal sofrer alteração */
          carregaTratamentos()
     },[subGrupoPrincipal])


     async function carregaGrupos() {
          const dados = await get ('/Grupo/v1')
          dados.unshift({id:0, nome:"SELECIONE---"})
          setGrupos(dados)
     }

     function renderSelectGrupo(){
          if(grupos !== undefined){
               return grupos.map(grupo =>{
                   return (<option value={grupo.id} key={grupo.id}> {grupo.nome} </option>)
               })
           }
     }


     async function carregaSubGrupos() {
          const dados = await get(`/SubGrupo/v1/Grupo/${grupoPrincipal}`)
          dados.unshift({id:0, nome:"SELECIONE---"})
          setSubGrupos(dados)
     }

     function renderSelectSubGrupo() {
          if(subGrupos !== undefined) {
               return subGrupos.map(subGrupo => {
                    return (<option value={subGrupo.id} key={subGrupo.id}> {subGrupo.nome} </option>)
               })
          }
     }


     async function carregaTratamentos () {
          const dados = await get(`/Formula/v1/SubGrupo/${subGrupoPrincipal}`)
          dados.unshift({id:0, nome:"SELECIONE---"})
          setTratamentos(dados)
     }

     function renderSelectTratamentos() {
          if (tratamentos !== undefined) {
               return tratamentos.map (tratamento => {
                    return (<option value={tratamento.id} key={tratamento.id} >{tratamento.nome}</option>)
               })
          }
     }

     function clear() {
          setGrupoPrincipal(0)
          setSubGrupoPrincipal(0)
          setTratamentoPrincipal(0)
          setComponente('')
     }

     function exit() {
          navigate("/")
     }

     async function save() {
          try {
               const form={
                    id: parseInt(tratamentoPrincipal),
                    nome: nomeFormula,
                    subgrupo_id:parseInt(subGrupoPrincipal),
                    componentes:componente
               }

               await put (`/Formula/v1/${tratamentoPrincipal}`,form )
               clear()
          }
          catch (error) {
               alert (error)
          }

     }

     function togglePostModal(item) {
          setShowPostModal(!showPostModal)

          if(item.nome =='Grupo'){
               carregaGrupos()
          }

          if(item.nome == 'SubGrupo'){
               carregaSubGrupos()
          }

          if(item.nome == 'Tratamento'){
               carregaTratamentos()
          }
     }



     function renderForm () {
          return (
               <div className="form">
                    <div className="col-md-6">
                         <label>Grupos:</label>
                         <div className="row">
                              <select name="Grupo"
                                   id="grupoPrincipal"
                                   className="form-control motivo"
                                   value={grupoPrincipal} onChange={(e) => {
                                        setSubGrupoPrincipal(0)  //limpa o campo Sub-grupo
                                        setTratamentoPrincipal(0)  //limpa o campo select das fórmulas
                                        setComponente('')  //limpa o campo text dos tratamentos
                                        setGrupoPrincipal(e.target.value)
                                   }}>

                                   {renderSelectGrupo()}
                              </select>

                              <button className="btn btn-success float-right"
                                   type="button"
                                   onClick={() => {
                                        setShowPostModal(!showPostModal)
                                        setOrigemSave({nome: 'Grupo'})
                                        }}>
                                   Incluir
                              </button>
                         </div>
                    </div>
                    <br/>

                    <div className="col-md-6">
                         <label>Sub-grupos:</label>
                         <div className="row">
                              <select name="subGrupo"
                                   id="subGrupoPrincipal"
                                   className="form-control motivo"
                                   value={subGrupoPrincipal}
                                   onChange={(e) => {
                                        setTratamentoPrincipal(0)  //limpa o campo select das fórmulas
                                        setComponente('')
                                        setSubGrupoPrincipal(e.target.value)
                                   }}>

                                   {renderSelectSubGrupo()}
                              </select>

                              {grupoPrincipal != 0 &&
                              <button className="btn btn-success float-right"
                                   type="button"
                                   onClick={() =>{
                                        setOrigemSave({nome:'SubGrupo', grupo_id: grupoPrincipal})
                                        setShowPostModal(!showPostModal)
                                        } }>Incluir
                              </button>
                              }
                         </div>
                    </div>
                    <br/>

                    <div className="col-md-6">
                         <label>Nome Tratamento:</label>
                         <div className="row">
                              <select
                                   id="tratamento"
                                   className="form-control motivo"
                                   value={tratamentoPrincipal}
                                   onChange={e => {
                                   var trat=tratamentos.filter(f => f.id == e.target.value)
                                   setComponente(trat[0].componentes)
                                   setTratamentoPrincipal(e.target.value)
                                   nomeFormula = e.target[e.target.selectedIndex].text
                                   
                                   }}>

                                   {renderSelectTratamentos()}
                              </select>

                              {subGrupoPrincipal != 0 &&
                              <button className="btn btn-success float-right"
                                   type="button"
                                   onClick={() => {
                                        setOrigemSave({nome:'Tratamento',subgrupo_id: subGrupoPrincipal})
                                        setShowPostModal(!showPostModal)
                                       
                                   }}>Incluir
                              </button>
                              }
                         </div>
                    </div>
                    <br/> 

                    <div className="col-md-6">
                         <label>Composição:</label>
                         <div className="row">
                              <textarea id="txtArea" value={componente}
                              onChange={e => {setComponente(e.target.value)}}
                              rows="10" cols="45">

                              </textarea>
                         </div>
                    </div>

                    <hr />
                    <div className="row">
                         <div className="col-12 d-flex buttons">
                              <button className="btn btn-primary"
                                   onClick={()=>save()}>
                                   Salvar
                              </button>
                              <button className="btn btn-secondary ml-2"
                                   onClick={() => clear()}>
                                   Cancelar
                              </button>
                              <button className="btn btn-secondary ml-2 sair"
                                   onClick={() => exit()}>
                                   Sair
                              </button>


                         </div>
                    </div>

                    {showPostModal && (
                         <ModalGrupo
                         origem= {origemSave}
                         close={togglePostModal}
                         />
                    )}

               </div>

          )
     }
     return (
          <Main {...headerProps}>
              {renderForm()}
          </Main>
       )

}

