import { showToast} from '../componentes/user/Toast';
//import { showToast} from './main/Toast'

export const baseApiUrl='https://webapihealthmetrix2.herokuapp.com/api'
//export const baseApiUrl='https://localhost:44340/api'
export const user = '__knowledge_user'
export const Key = '__knowledge_user'
export const authKey='authKey'
export const userKey = '{"usuario":"William","senha":"Teste"}'


export function showError(e){
   if(e && e.response && e.response.data){
       showToast({type:'error', message: e.response.data})
   }else if (typeof e === 'string'){
       showToast({type:'error', message: e})

   }else{
       console.log(e);
       showToast({type:'error', message: "Erro padrão"})
   }
}