import React from "react";
import Main from '../Main';
import './Home.css'
import manipulado from '../../../src/imagem/manipulado.jpg'
export default props =>
     <Main icon="home" title="Início" >
          <div className='bandeira'>

{/*
          <a className="Brasil" href="#page-top"><span class="brasil"></span></a>
          <a className="Espanha" href="#page-top"><span class="espanha"></span></a>
*/}
               <img src={manipulado} alt="foto de remédios" id="foto" />
          </div>
     </Main>