import React, { useState, useRef, useEffect} from 'react';
import JoditEditor from 'jodit-react';

import Main from '../Main'
import './Prescricao.css'
import {get} from '../../main/register'
import Tree from "react-animated-tree-v2";

//React Draft Wysiwyg -- é pago não altera tamanho, fonte, tipo e entrelinhas muito alto
//ReactQuill -- Não respeita o <cr> do texto original, não permite alterar parte da linha

//Draft.Js -- não funcionou
//React-rte -- não funcionou



const headerProps = {
     icon: 'file-text-o',
     title: 'Prescrição dos Tratamentos',
}

//const EditorTeste = () => {
export default function EditorTeste () {
	const [transfere, setTransfere] = useState("")
	const [content, setContent] = useState('');
	const [tratamentos, setTratamentos]=useState([])
     const editor = useRef(null)


     useEffect(() =>{
       buscaTratamento()
     },[tratamentos])

     async function buscaTratamento() {
          const trat= await get('/Tratamento/v1');
          setTratamentos(trat);
     }


     function pegaTexto(e, texto){
          switch (e.detail) {
               case 1: {
                    break
               }
               case 2: {
                    setTransfere (transfere + texto)
                    break
               }
               default: return
          }
     }



	function limpa(e) {
		setContent ('')
	}


     function renderTreeView(){
          return (

               <div  className="treeviewmed">
                     {tratamentos.map((tratamento, index) => {

                    return (
                         <Tree key={index}
                         content ={tratamento.nome}
                         itemId={tratamento.id}>
                         {tratamento.tiposTratamentos.map((tiposTratamento, index) => {
                              return (
                                   <Tree key={index}
                                   content={tiposTratamento.nome}
                                   itemId ={tiposTratamento.id}>
                                        {tiposTratamento.medicamentos.map ((medicamento, index) =>{
                                             return (
                                                  <Tree key={index}
                                                  content={medicamento.nome}
                                                  itemId={medicamento.id}>
                                                       <textarea id="txtArea1" rows="10" cols="55"
                                                       value={medicamento.componente.nome}
                                                       readOnly onClick={(e) => pegaTexto(e, medicamento.componente.nome)}>{medicamento.componente.nome}

                                                       </textarea>
                                                       <span></span>

                                                  </Tree>
                                                  
                                             );
                                        })}
                                   </Tree>
                              );
                         })}
                         </Tree>
                    );
               })}
               </div>
              
          );
     }

	function renderForm() {
	return (
          <>
          <div className="form">
               <div className="row">
                    <div className="col-12 col-md-6">

                         <label>Prescrição:</label>
                         <JoditEditor
                              ref={editor}
                              value={transfere}
                              //tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) => setTransfere(newContent)} // preferred to use only this option to update the content for performance reasons
                              //onChange={e => setTransfere(e.target.value)}
                              //onChange={(newContent) => {}}
                         />

                    </div>

                    <div className="col-12 col-md-6">
                         <div className="form-group">
                              {tratamentos != undefined ? renderTreeView() : null}
                         </div>
                    </div>
               </div>
          </div>
          </>


	);
	}

	return (
          <Main {...headerProps} >
               {renderForm()}
          </Main>
     )
};
