import axios from 'axios'
import {baseApiUrl} from '../main/global'
import {getLocalStorage,setLocalStorage} from '../store/config/storage';
import {getRefreshToken} from '../store/config/token'


const baseUrl=baseApiUrl;

let headers ={
    'Access-Control-Allow-Origin': '*',
    // Request methods you wish to allow
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // Request headers you wish to allow
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type,authorization',
    'Access-Control-Allow-Credentials': true
 };

if(localStorage.getItem("acessToken")){
    headers.Authorization=`Bearer ${getLocalStorage("acessToken")}`
}



const api = axios.create({
  headers: headers,
  baseURL: baseUrl,
});

 api.interceptors.response.use(
    async response => {
      return response
    },
    async function ( error) {
      const originalRequest = error.config
  
      
  
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const token = await  getRefreshToken()

       //console.log(token)
        

       const teste= axios.create({
          headers: headers,
          baseURL: baseUrl,
        });
        return  teste.post('/Autenticacao/v1', {
            usuario:"William", senha: "Teste"
          })
          .then(res => {
                if (res.status === 200 || res.status === 201) {
          
                  const valor=token
                  setLocalStorage("acessToken",valor.token,20);
                 setLocalStorage("expiration",valor.expiration,20);
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + res.data.acessToken
                return axios(originalRequest)
            }
          })
      }
      return Promise.reject(error)
    }
  )


    export default api;

