import React, { useState } from 'react'
import {put} from '../../../main/register'
import './ModalAlteraGrupo.css'


export default function ModalAlteraGrupo ({origem, close}) {
     const [novaDescricao, setNovaDescricao] = useState()

     async function save() {
          try {

               if (origem.localOrigem == "Grupos") {
                    const form = {
                    id: parseInt(origem.id),
                    nome: novaDescricao
               }
               await put ("/Grupo/v1/"+form.id, form)

               }else if (origem.localOrigem == "SubGrupos"){
                    const form = {
                         id: parseInt(origem.id),
                         nome: novaDescricao,
                         grupo_id: parseInt(origem.grupo_id)
                    }
                    await  put ("/SubGrupo/v1/"+form.id, form)
  
               }else if (origem.localOrigem == "Tratamentos"){
                    const form = {
                         id: parseInt(origem.id,),
                         nome: novaDescricao,
                         subgrupo_id: parseInt(origem.subgrupo_id),
                         componentes: origem.compon
                    }
                    await put ("/Formula/v1/"+form.id, form)
               }
               
          } catch (error) {
           alert (error)
          }
           close(origem)
       }

     return (
          <div className="modal1">

               <div className="container">

                    <label className="label">Descrição atual:</label>
                    <input type="text"className="form-control" value={origem.descricao} readOnly>
                    </input>

                    <label className="label1">Nova Descrição:</label>
                    <input type="text" value={novaDescricao} autoFocus
                           className="form-control"
                           onChange={(e) => setNovaDescricao(e.target.value.toUpperCase())} >
                    </input>

                    <hr/>
                    
                    <div className="col-12 d-flex justify-content-end">
                         <button className="btn btn-primary" onClick={save}>
                              Salvar
                         </button>
{/*
                         <button className="btn btn-secondary ml-2 " onClick={close(origem)}>
                              Sair
                         </button>
     */}
                    </div>

               </div>
          </div>
     )
}

