import {getLocalStorage,setLocalStorage} from './storage';
import {baseApiUrl} from '../../main/global';
import axios from 'axios'

const baseUrl=baseApiUrl;

let headers ={
    'Access-Control-Allow-Origin': '*',
    // Request methods you wish to allow
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // Request headers you wish to allow
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type,authorization',
    'Access-Control-Allow-Credentials': true
 };

export function getAccessToken(){

    let token= {token: getLocalStorage("acessToken"),expiration: getLocalStorage("expiration")};

    return token.token;
}


const api = axios.create({
    headers: headers,
    baseURL: baseUrl,
  });

export async function getRefreshToken(){


    localStorage.clear("acessToken");
    localStorage.clear("expiration");

    const data ={ usuario:"William", senha: "Teste"}


   const resp= await  api.post("/Autenticacao/v1",data)
     .then((response)=>{

       

         return {token: response.data.acessToken,expiration: response.data.expiration}
     })
     .catch(error =>{
         console.log(error.response);
         
         return {error:error.response, success: false };
     })

     return resp
}