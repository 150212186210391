import React, { useState } from 'react'
import {post} from '../../../main/register'
import './ModalGrupo.css'


export default function ModalSubGrupo ({origem, close}) {
         const [dados, setDados] = useState({id: 0, nome: '', grupo_id: origem.grupo_id == undefined ? 0 :  parseInt(origem.grupo_id), subgrupo_id: origem.subgrupo_id == undefined ? 0 : parseInt(origem.subgrupo_id) })

   
           async function save() {
              try {
                  if (origem.nome == "Grupo") {
                      await post ("/Grupo/v1", dados)
     
                    }else if (origem.nome == "SubGrupo"){
                         await  post ("/SubGrupo/v1", dados)
      
                   }else if (origem.nome == "Tratamento"){
                        await post ("/Formula/v1", dados)
                   }
                   
              } catch (error) {
               alert (error)
              }

               close(origem)
           }



     return (
          <div className="modal1">
               <div className="container">
                    
                    <label>Novo cadastro:</label>
                    <input autoFocus type="text"
                           value={dados.nome}
                           className="form-control"
                         onChange={e => setDados({...dados, nome: e.target.value.toUpperCase()})} >
                    </input>

                    <hr/>
                    
                    <div className="col-12 d-flex justify-content-end">
                         <button className="btn btn-primary" onClick={save}>
                              Salvar
                         </button>
                         <button className="btn btn-secondary ml-2 " onClick={() => close(origem)}>
                              Sair
                         </button>

                    </div>

               </div>
          </div>
     )
}

