import React, { useState, useEffect } from "react"
import Main from "../Main"
import './AlteraGrupo.css'
import { useNavigate } from "react-router-dom"
import ModalAlteraGrupo from "./modal/ModalAlteraGrupo"

import { get } from '../../main/register'

const headerProps = {
     icon: 'wrench',
     title: 'Altera nomes'
}

export default function Tratamentos() {
     const [grupos, setGrupos] = useState([])
     const [showPostModal, setShowPostModal] = useState(false)
     const [tratamentos, setTratamentos] = useState([])
     const [textoGrupo, setTextoGrupo] = useState('')
     const [textoSubGrupo, setTextoSubGrupo] = useState('')
     const [textoTratamento, setTextoTratamento] = useState('')
     const [subGrupos, setSubGrupos] = useState([]) 
     const [grupoPrincipal, setGrupoPrincipal] = useState(0)
     const [subGrupoPrincipal, setSubGrupoPrincipal] = useState(0)
     const [tratamentoPrincipal, setTratamentoPrincipal] = useState(0)
     const [componente,setComponente]=useState('')
     const navigate = useNavigate()
     const [origemSave, setOrigemSave] = useState ({})


     useEffect(() => {     /* busca Grupos na API quando é renderizado */
          carregaGrupos()
     }, [grupoPrincipal])

     useEffect(()=>{     /* Chama a função CarregaSubGrupos toda vez que o GrupoPrincipal sofrer alteração */
          carregaSubGrupos()
     },[grupoPrincipal])

     useEffect(() =>{    /* Chama a função CarregaTratamentos toda vez que o subGrupoPrincipal sofrer alteração */
          carregaTratamentos()
     },[subGrupoPrincipal])


     async function carregaGrupos() {
          const dados = await get ('/Grupo/v1')
          dados.unshift({id:0, nome:"SELECIONE---"})
          setGrupos(dados)
     }

     function renderSelectGrupo(){
          if(grupos !== undefined){
               return grupos.map(grupo =>{
                   return (<option value={grupo.id} key={grupo.id}> {grupo.nome} </option>)
               })
           }
     }


     async function carregaSubGrupos() {
          const dados = await get(`/SubGrupo/v1/Grupo/${grupoPrincipal}`)
          dados.unshift({id:0, nome:"SELECIONE---"})
          setSubGrupos(dados)
     }

     function renderSelectSubGrupo() {
          if(subGrupos !== undefined) {
               return subGrupos.map(subGrupo => {
                    return (<option value={subGrupo.id} key={subGrupo.id}> {subGrupo.nome} </option>)
               })
          }
     }


     async function carregaTratamentos () {
          const dados = await get(`/Formula/v1/SubGrupo/${subGrupoPrincipal}`)
          dados.unshift({id:0, nome:"SELECIONE---"})
          setTratamentos(dados)
     }

     function renderSelectTratamentos() {
          if (tratamentos !== undefined) {
               return tratamentos.map (tratamento => {
                    return (<option value={tratamento.id} key={tratamento.id} >{tratamento.nome}</option>)
               })
          }
     }

     function clear() {
          setGrupoPrincipal(0)
          setSubGrupoPrincipal(0)
          setTratamentoPrincipal(0)
     }

     function togglePostModal(item) {
          setShowPostModal(!showPostModal)
          if(item.localOrigem == 'Grupos'){
               alert('Descrição do Grupo alterada com sucesso')
               carregaGrupos()
          }

          if(item.localOrigem == 'SubGrupos'){
               alert ('Descrição do Sub-grupo alterada com sucesso')
               carregaSubGrupos()
          }

          if(item.localOrigem == 'Tratamentos'){
               alert ('Descrição do Tratamento alterada com sucesso')
               carregaTratamentos()
          }
     
     }

     function exit() {
          navigate("/")
     }

     function renderForm () {
          return (
               <div className="form">
                    <div className="col-md-6">
                         <label>Grupos:</label>
                         <div className="row">
                              <select name="Grupo" id="grupoPrincipal"
                                   className="form-control grupo"
                                   value={grupoPrincipal}  onChange={(e) => {
                                        setSubGrupoPrincipal(0)  //limpa o campo Sub-grupo
                                        setTratamentoPrincipal(0)  //limpa o campo select das fórmulas
                                        setGrupoPrincipal(e.target.value)
                                        let index = e.target.selectedIndex;
                                       
                                        setTextoGrupo( e.target[index].text)
                                   }}>

                                   {renderSelectGrupo()}
                              </select>
                              {grupoPrincipal != 0 &&
                              <div>
                                   <button className="btn btn-warning float-right"
                                        type="button"
                                        onClick={() => {
                                             setShowPostModal(!showPostModal)
                                             setOrigemSave({id: grupoPrincipal, descricao: textoGrupo, localOrigem: 'Grupos'})
                                            
                                             }}>
                                        Alterar
                                   </button>
                              </div>
                              }

                              
                         </div>
                    </div>
                    <br/>

                    <div className="col-md-6">
                         <label>Sub-grupos:</label>
                         <div className="row">
                              <select name="subGrupo" id="subGrupoPrincipal"
                                   className="form-control sub-grupo"
                                   value={subGrupoPrincipal}
                                   onChange={(e) => {
                                        setTratamentoPrincipal(0)  //limpa o campo select das fórmulas
                                        setSubGrupoPrincipal(e.target.value)
                                        let index = e.target.selectedIndex;
                                        setTextoSubGrupo( e.target[index].text)
                                   }}>

                                   {renderSelectSubGrupo()}
                              </select>

                              {subGrupoPrincipal != 0 &&
                              <div>
                                   <button className="btn btn-warning float-right"
                                        type="button"
                                        onClick={() =>{
                                             setShowPostModal(!showPostModal)
                                             setOrigemSave({id: subGrupoPrincipal, descricao: textoSubGrupo, grupo_id: grupoPrincipal, localOrigem: 'SubGrupos'})
                                             } }>Alterar
                                   </button>
                              </div>
                              }
                         </div>
                    </div>
                    <br/>

                    <div className="col-md-6">
                         <label>Nome Tratamento:</label>
                         <div className="row">
                              <select
                                   id="tratamento" className="form-control tratamento"
                                   value={tratamentoPrincipal}
                                   onChange={e => {
                                   var trat=tratamentos.filter(f => f.id == e.target.value)
                                   setTratamentoPrincipal(e.target.value)

                                   let index = e.target.selectedIndex;
                                   setTextoTratamento( e.target[index].text)

                                   setComponente(trat[0].componentes)
                                   }}>

                                   {renderSelectTratamentos()}
                              </select>

                              {tratamentoPrincipal != 0 &&
                              <div>
                                   <button className="btn btn-warning float-right" type="button"
                                        onClick={() => {
                                             setShowPostModal(!showPostModal)
                                             setOrigemSave({id: tratamentoPrincipal, descricao: textoTratamento, subgrupo_id: subGrupoPrincipal, compon: componente, localOrigem:'Tratamentos'})
                                        }}>Alterar
                                   </button>

                              </div>
                              }
                         </div>

                         <div className="row">
                              <div className="col-12 d-flex" id="limpar">
                                   <button className="btn btn-secondary ml-2"
                                        onClick={() => clear()}>
                                        Limpar
                                   </button>

                                   <button className="btn btn-secondary ml-2 sair"
                                        onClick={() => exit()}>
                                        Sair
                                   </button>

                              </div>
                         </div>

                         </div>



                    <br/> 

                    {showPostModal && (
                         <ModalAlteraGrupo
                         origem= {origemSave}
                         close={togglePostModal}
                         />
                    )}

               </div>

          )
     }
     return (
          <Main {...headerProps}>
              {renderForm()}
          </Main>
       )

}

