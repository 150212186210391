import './Nav.css'
import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css'
import { NavLink } from 'react-router-dom'

export default class Nav extends Component {
     state={clicked: false}

     handleClick = () => {
         this.setState({clicked: !this.state.clicked})
     }

     render() {
          return (
          <aside className="menu-area">
               <nav className="menu">
                    <div id="menu-div">
                         <ul id="menu-desktop" className={this.state.clicked ? "#menu-desktop active" : "#menu-desktop"}>
                              <NavLink to="/" >
                                   <i className="fa fa-home"></i> Início
                              </NavLink>
                              <NavLink to="/tratamentos">
                                   <i className="fa fa-plus-square"></i> Fórmulas
                              </NavLink>

                              <NavLink to="/prescricao">
                                   <i className="fa fa-file-text-o"></i> Prescrição
                              </NavLink>

                              <NavLink to="/alteragrupo">
                                   <i className="fa fa-wrench"></i> Manutenção
                              </NavLink>
                         </ul>
                    </div>

                    <div id="mobile" onClick={this.handleClick}>
                         <i
                         id="bar"
                         className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars' }
                         ></i>
                    </div>
               </nav>

          </aside>
          )
     }
}