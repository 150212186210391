import './Logo.css'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../imagem/logo.jpg'

export default function Logo() {
     return (
         <aside className="logo">
             <Link to="/" className="logo">
                 <img src={logo} alt="logo"/>
             </Link>
         </aside>
     );
 }