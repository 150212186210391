import api from './axios'

export const post=async(url,data)=>{
   
        const result= await  api.post(url,data).then((response)=>{
          
         if(response.data != null){
             return response.data; 
            }
        })
        .catch(error =>{
            return null;
    })
    return result

}


export const put=(url,data)=>{
console.log(url,data);
       api.put(url,data)
        .then((response)=>{
       if(response.data != null){
            return JSON.parse(response.data) 
       }
    })
        .catch(error =>{
              
        console.log(error.response);
        return null;
     })

}

export const get=(url)=>{

       return api.get(url)
        .then((response)=>{
       if(response.data != null){
        
        return response.data;
       }else{
           return null;
       }
    })
    .catch(error =>{
        console.log(error.response);
    })
}

export const del=(url)=>{

       return api.delete(url)
    .then((response)=>{
       if(response.data != null){
        
         return response.data;
         }else{
           return null;
         }
        })
        .catch(error =>{
        console.log(error.response);
        })

 }

 

 
 