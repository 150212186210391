import { authKey } from "../../main/global";
import {getLocalStorage} from './storage';


export const isAuthenticated = () => {

 const user=getLocalStorage(authKey);

    if(user){
        return true;
    }else{
        if(!window.location.href.includes('/auth') ){
        window.location.replace('/auth');
      //  window.location.reload();
        }
        
        return false;
    }
    
};