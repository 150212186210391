import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.css'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { isAuthenticated } from '../store/config/auth'

import Logo from '../componentes/Logo'
import Nav from '../componentes/Nav'
import Routes from './Routes'
import Footer from '../componentes/Footer'

export default props =>
<BrowserRouter>

     <div id='app' className= {isAuthenticated() ? '' :'hide-menu'}>
     <ToastContainer autoClose={3000} />
     { isAuthenticated() ?<Logo /> :undefined}
     { isAuthenticated() ?<Nav /> :undefined}
          <Routes/>
          <Footer/>

     </div>
</BrowserRouter>