import React from "react"
import {Routes, Route,Navigate} from 'react-router-dom'

import { isAuthenticated } from '../store/config/auth'

import Home from '../componentes/home/Home'
import Tratamentos from "../componentes/user/Tratamentos"
import Prescricao from '../componentes/user/Prescricao'
import Auth from "../pages/Auth/Auth"
import AlteraGrupo from "../componentes/user/AlteraGrupo"
import EditorTeste from "../componentes/user/EditorTeste"

const PrivateRoute = ({
    redirectPath = '/auth',
    children,
  }) => {
    if ( isAuthenticated() === false) {
 
      return <Navigate to={redirectPath} replace />;
    }
  
    return children;
  };


  const OpenRoute = ({
    redirectPath = '/',
    children,
  }) => {
    if ( isAuthenticated() === true) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children;
  };

     

export default props =>
<Routes>
     <Route exact path='/' element={<PrivateRoute>
              <Home />
            </PrivateRoute>} />
     <Route exact path='/auth' element={<OpenRoute>
        <Auth />
            </OpenRoute>} />
     <Route path='/prescricao' element = {<PrivateRoute>
        <EditorTeste />
            </PrivateRoute>  } />
     <Route path='/tratamentos' element = {<PrivateRoute>
        <Tratamentos />
            </PrivateRoute> } />
      <Route path='/alteragrupo' element = {<PrivateRoute>
        <AlteraGrupo />
            </PrivateRoute> } />
            
     <Route path="*" element={
     <PrivateRoute>
     <Home />
   </PrivateRoute>} />
</Routes>

